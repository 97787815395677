<template>
  <KeepAlive>
    <b-card-code>
      <validation-observer ref="simpleRules">
        <b-form style="" @submit.prevent>
          <b-row cols="1" cols-sm="1" cols-xsm="1" cols-md="1" cols-lg="2">
            <!--  price -->
            <b-col>
              <b-form-group label="المبلغ" label-for="v-price">
                <validation-provider #default="{ errors }" name="المبلغ" rules="required">
                  <b-form-input
                    id="v-price"
                    v-model.number="coupon.price"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder=" المبلغ بالجنيه"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- company -->
            <b-col>
              <b-form-group label="الشركة" label-for="v-company">
                <validation-provider #default="{ errors }" name="الشركة" rules="required">
                  <v-select
                    v-model="coupon.companyName"
                    :options="companies"
                    :getOptionLabel="(comp) => comp.userDisplayName"
                    :reduce="(comp) => comp.userDisplayName"
                    :class="{ 'is-invalid': errors.length > 0 }"
                    :state="errors.length === 0 ? null : false"
                    dir="rtl"
                    placeholder="اختر الشركة"
                  ></v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- coupon number -->
            <b-col>
              <b-form-group label="رقم القسيمة" label-for="v-coupon">
                <validation-provider #default="{ errors }" name="رقم القسيمة" rules="required">
                  <b-form-input id="v-coupon" v-model="coupon.couponNumber" placeholder="رقم القسيمة" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- policy number -->
            <b-col>
              <b-form-group label="رقم البوليصه" label-for="v-policy">
                <b-form-input id="v-policy" v-model="coupon.policyNumber" placeholder="رقم البوليصه" />
              </b-form-group>
            </b-col>
            <!-- bill number -->
            <b-col>
              <b-form-group label="رقم الفاتورة" label-for="v-bill">
                <b-form-input id="v-coupon" v-model="coupon.billNumber" placeholder="رقم الفاتورة" />
              </b-form-group>
            </b-col>
            <!-- certificate number -->
            <b-col>
              <b-form-group label="رقم الشهاده" label-for="v-certificate">
                <b-form-input id="v-certificate" v-model="coupon.certificateNumber" placeholder="رقم القسيمة" />
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="التاريخ" label-for="v-date">
                <validation-provider #default="{ errors }" name="التاريخ" rules="required">
                  <b-form-datepicker
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="ar"
                    v-model="coupon.date"
                    placeholder="تاريخ القسيمة"
                    :state="errors.length > 0 ? false : null"
                    today-button
                    reset-button
                    close-button
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="التفاصيل" label-for="v-details">
                <validation-provider name="التفاصيل" rules="required" v-slot="{ errors }">
                  <v-select
                    v-model="coupon.details"
                    :options="detailsOptions"
                    :reduce="(label) => label"
                    dir="rtl"
                    placeholder="نوع البيان"
                    :state="errors.length === 0 ? null : false"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <b-form-file accept="image/*" v-model="selectedFile"></b-form-file>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button v-if="editCoupon" type="submit" variant="primary" class="mr-1" @click="validationForm" :disabled="isValidating"> تعديل </b-button>
              <b-button v-else :disabled="isValidating" type="submit" variant="primary" class="mr-1" @click="validationForm"> إضافة </b-button>
              <b-button variant="primary" style="margin-right: 15px" @click="this.back"> رجوع </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
  </KeepAlive>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormRadio,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormFile
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckboxGroup,
    BFormRadio,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormDatepicker,
    BFormFile,
    vSelect
  },

  directives: {
    Ripple
  },
  props: ['id'],
  data() {
    return {
      selectedFile: null,
      companies: [],
      locale: 'ar',
      required,
      detailsOptions: [
        'قسائم المطار',
        'إذن تسليم',
        'أرضية',
        'نافذه موحده',
        'عرض كيمياء',
        'عرض واردات',
        'مهن طبية',
        'هيئة طرق',
        'قسيمة ميدل ايست',
        'عرض أمن عام',
        'عرض دمغة وموازين',
        'حماية مدنية',
        'قسائم الصيدلية',
        'افراج هيئة الدواء(عينه)',
        'افراج هيئة الدواء(خامه)',
        'قسيمة موافقة استيرادية',
        'قسيمة دمغه طبية',
        'قسيمة اذن جلب',
        'قسيمة اذن سحب',
        'قسيمة تصدير'
      ],

      isValidating: false,
      editCoupon: false,
      coupon: {
        price: null,
        couponNumber: null,
        billNumber: null,
        certificateNumber: null,
        policyNumber: null,
        date: null,
        details: null,
        companyName: null
      }
    }
  },
  async created() {
    if (this.$route.params.id) {
      this.editCoupon = true
      await this.GetCoupon()
    }
    this.GetCompanies()
  },
  mounted() {
    // switch to arabic in validation
    localize(this.locale)
  },
  methods: {
    async GetCoupon() {
      try {
        const response = await this.$store.dispatch('GetCoupon', this.$route.params.id)
        if ((response.status = 200)) {
          this.coupon = response.data
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    async GetCompanies() {
      try {
        const response = await this.$store.dispatch('GetCompanies')
        if ((response.status = 200)) {
          this.companies = response.data
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },

    async validationForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        this.isValidating = true

        if (success && !this.editCoupon) {
          await this.AddCoupon()
        } else if (success && this.editCoupon) {
          await this.EditCoupon()
        }
        this.isValidating = false
      })
    },
    async AddCoupon() {
      const formData = new FormData()
      formData.append('companyName', this.coupon.companyName)
      formData.append('price', this.coupon.price)
      if (this.coupon.billNumber) {
        formData.append('billNumber', this.coupon.billNumber)
      }
      if (this.coupon.certificateNumber) {
        formData.append('certificateNumber', this.coupon.certificateNumber)
      }
      formData.append('couponNumber', this.coupon.couponNumber)
      if (this.coupon.policyNumber) {
        formData.append('policyNumber', this.coupon.policyNumber)
      }
      formData.append('date', this.coupon.date)
      formData.append('details', this.coupon.details == null ? '' : this.coupon.details)
      formData.append('file', this.selectedFile)

      try {
        const response = await this.$store.dispatch('CreateCoupon', formData)
        if (response.status === 200) {
          this.$router.push({ name: 'coupons' })
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    async EditCoupon() {
      const formData = new FormData()
      formData.append('id', this.$route.params.id)
      formData.append('AgentId', this.coupon.agentId)
      formData.append('companyName', this.coupon.companyName)
      formData.append('price', this.coupon.price)
      formData.append('billNumber', this.coupon.billNumber)
      formData.append('certificateNumber', this.coupon.certificateNumber)
      formData.append('couponNumber', this.coupon.couponNumber)
      formData.append('policyNumber', this.coupon.policyNumber)
      formData.append('date', this.coupon.date)
      formData.append('details', this.coupon.details == null ? '' : this.coupon.details)
      formData.append('file', this.selectedFile)

      try {
        const response = await this.$store.dispatch('UpdateCoupon', formData)
        if (response.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم التعديل بنجاح',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
          this.$router.push({ name: 'coupons' })
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    back() {
      this.$router.push({
        name: 'coupons'
      })
    }
  }
}
</script>
<style scoped>
::v-deep .dropdown-menu {
  width: 270px;
}

::v-deep .vs__dropdown-toggle {
  padding: 0.5rem;
}
::v-deep [dir] .vs__dropdown-toggle {
  padding: 0 0 4px;
  background: none;
  border-radius: 4px;
  padding: 0.4rem;
}
</style>
